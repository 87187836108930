<div class="form-check mb-4">
  <input class="form-check-input" type="radio" value="true" id="true" name="radio2" (change)="valueChange.emit(true)">
  <label class="form-check-label" for="true">
    {{'true' | translate}}
  </label>
</div>

<div class="form-check">
  <input class="form-check-input" type="radio" value="false" id="false" name="radio2" (change)="valueChange.emit(false)">
  <label class="form-check-label" for="false">
    {{'false' | translate}}
  </label>
</div>
