import {Component, EventEmitter, Input, Output} from '@angular/core';
import {QuestionDto} from "../../../data/objects/question.dto";

@Component({
  selector: 'form-base',
  templateUrl: './form-base.component.html',
  standalone: true,
  styleUrls: ['./form-base.component.scss']
})
export class FormBaseComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() subline: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() question: QuestionDto;
  @Output() valueChange = new EventEmitter<any>();

}
