import { Component, Input } from '@angular/core';

@Component({
  selector: 'form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss']
})
export class FormLabelComponent {
  @Input() id  : string;
  @Input() label: string;
  @Input() subline: string;
  @Input() required:boolean;

}
