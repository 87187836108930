import { Component } from '@angular/core';
import { FormBaseComponent } from '../form-base/form-base.component';

@Component({
  selector: 'form-url',
  templateUrl: './form-url.component.html',
  styleUrls: ['./form-url.component.scss']
})
export class FormUrlComponent extends FormBaseComponent{

}
