import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormService } from '../form.service';
import { ActivatedRoute } from '@angular/router';
import { FillFormEnum } from '../../data/enums/fill-form.enum';
import { StatusCode } from '../../share/config/statusCode';
import { ToastrService } from 'ngx-toastr';
import { DataTypeEnum } from '../../data/enums/data-type.enum';
import { TextUtils } from '../../share/utils/text.service';
import { FormDto } from '../../data/objects/form.dto';
import { UrlUtil } from '../../share/utils/url.util';
import { ApiService } from '../../share/services/api/api.service';
import {BaseResponse} from "../../data/response/base.response";

@Component({
  selector: 'fill-form',
  templateUrl: './fill-form.component.html',
  styleUrl: './fill-form.component.scss',
})
export class FillFormComponent implements OnChanges {
  protected readonly FillFormEnum = FillFormEnum;
  @Input() formCode: string | null = null;
  @Input() formType: FillFormEnum;
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  form: FormDto;
  Types = DataTypeEnum;
  loaded: boolean = false;
  submitLoading: boolean = false;
  isRtl: boolean = false;
  orgId: number;

  constructor(
    private formService: FormService,
    private route: ActivatedRoute,
    private textUtils: TextUtils,
    private urlUtil: UrlUtil,
    private api: ApiService,
    private _toastr: ToastrService,
  ) {
    if (!localStorage.getItem('org')) this.getOrgInfo(this.urlUtil.getDomain());
    else this.orgId = Number(localStorage.getItem('org'));
  }

  async ngOnChanges() {
    this.loaded = false;
    if (this.formCode == undefined) {
      this.route.params.subscribe(async (params: any) => {
        if (params['form_code']) this.formCode = params['form_code'];
      });
    }
    this.formType = this.formType ?? FillFormEnum.PUBLIC;
    let response = [FillFormEnum.INTERNAL, FillFormEnum.PREVIEW].includes(
      this.formType as FillFormEnum,
    )
      ? await this.formService.getFormByAuthentication(this.formCode!!)
      : await this.formService.getFormWithoutAuthentication(this.formCode!!);
    if (response.statusCode == 200) {
      this.form = response.data;
      const questions = this.form.sections[0].questions;
      const questionsOrder: number[] = this.form.sections[0].questionsOrder;
      questions.forEach((question) => {
        const find = response.data.attributesOptions.find(
          (attr) => attr.id == question.attributeId,
        );
        question.options = find.options;
        this.isRtl = this.textUtils.isRtl(question.label + question.subLine) || this.isRtl;
      });
      if (questionsOrder?.length) {
        this.form.sections[0].questions = questionsOrder.map(
          (order) => questions.find((question) => question.id == order)!!,
        );
      }
      this.loaded = true;
    }
  }

  getOrgInfo(domain: string) {
    this.api.getOrgDetails(domain.toLowerCase()).subscribe({
      next: (response: any) => {
        this.orgId = response.data.id;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {},
    });
  }

  async submitForm() {
    this.submitLoading = true;
    const requiredFields: string[] = [];
    const questions = this.form?.sections[0].questions;
    const questionBody = {};
    questions?.forEach((question) => {
      if (question.isRequired && !question.value) requiredFields.push(question.label);
      else questionBody[question.id] = question.value;
    });
    if (requiredFields.length == 0) {
      await this.formService
        .insertForm(this.formCode, this.formType, questionBody)
        .then((response) => {
          this.submitLoading = false;
          this.reload.emit(true);

          BaseResponse.isSuccess(response)
            ? this._toastr.success('Insert form successful.')
            : this._toastr.error(StatusCode[response.statusCode]);
        });
    } else {
      this._toastr.error(
        `Filling in the ${requiredFields.join(', ')} field${requiredFields.length > 1 ? 's' : ''} is mandatory.`,
      );
      this.submitLoading = false;
    }
  }
}
