import {Component, Input} from '@angular/core';
import {FormBaseComponent} from '../form-base/form-base.component';

@Component({
  selector: 'form-short-text',
  templateUrl: './form-short-text.component.html',
  styleUrls: ['./form-short-text.component.scss']
})
export class FormShortTextComponent extends FormBaseComponent {
  @Input() isRtl = false;
}
