import {Component, Input} from '@angular/core';
import {FormBaseComponent} from "../form-base/form-base.component";
import {DataTypeEnum} from "../../../data/enums/data-type.enum";

@Component({
  selector: 'form-item',
  templateUrl: './form-item.component.html',
  styleUrl: './form-item.component.scss'
})
export class FormItemComponent extends FormBaseComponent {
  protected readonly DataTypeEnum = DataTypeEnum;
  @Input() isRtl: boolean = false;
  @Input() orgId: number;

  onValueChange(value) {
    this.valueChange.emit(value);
  }
}
