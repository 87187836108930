import {Component, Input} from '@angular/core';
import {FormBaseComponent} from '../form-base/form-base.component';
import {FilesApiService} from "../../../data/api/files-api.service";
import {StatusCode} from "../../../share/config/statusCode";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'form-file',
  templateUrl: './form-file.component.html',
  styleUrls: ['./form-file.component.scss']
})
export class FormFileComponent extends FormBaseComponent {
  @Input() orgId: number;

  constructor(private fileApi: FilesApiService,
              private _toastr: ToastrService) {
    super();
  }

  async upload(event) {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('orgId', this.orgId.toString());

    await this.fileApi.uploadFormFile(formData).then(response => {
      this.valueChange.emit(response.data.name);
    }, error => {
      this._toastr.error(StatusCode[error.error.statusCode]);
    });
  }
}
