import {Component} from '@angular/core';
import {FormBaseComponent} from "../form-base/form-base.component";

@Component({
  selector: 'form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.sass']
})
export class FormCheckboxComponent extends FormBaseComponent {

}
