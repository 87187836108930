import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBaseComponent} from '../form-base/form-base.component';
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {CUSTOM_DATE_FORMATS, JalaliMomentDateAdapter} from "../../../share/utils/jalali-adapter";
import {BasicsRepository} from "../../../data/repository/basics.repository";
import moment from "moment-timezone";

@Component({
  selector: 'form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss']
})
export class FormDateComponent extends FormBaseComponent implements OnInit {
  @ViewChild('date_picker') datePicker;
  selected: Date | null;
  selectedDate: string | undefined;

  constructor(@Inject(DateAdapter) private dateAdapter: JalaliMomentDateAdapter,
              @Inject(MAT_DATE_FORMATS) private formats: CUSTOM_DATE_FORMATS,
              private baseRepo: BasicsRepository) {
    super();
  }

  ngOnInit() {
    let locale = this.baseRepo.getCalendarType();
    this.dateAdapter.setLocale(locale);
    this.formats.locale = locale;
  }

  selectDate() {
    this.selectedDate = moment(this.selected).format('YYYY-MM-DD');
    this.valueChange.emit({startDate: this.selectedDate});
    this.datePicker.close();
  }
}
