import { Injectable } from '@angular/core';
import { FormApiService } from '../data/api/form-api.service';
import { ItemEvents } from '../events/item.events';
import { BaseResponse } from '../data/response/base.response';
import { FillFormEnum } from '../data/enums/fill-form.enum';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(
    private formapi: FormApiService,
    private itemEvents: ItemEvents,
  ) {}

  /*async getFormById(formId: number) {
    return (await this.formapi.getFormById(formId)).data;
  }*/

  async getFormByAuthentication(code: string) {
    return await this.formapi.getFormByAuthentication(code);
  }

  async getFormWithoutAuthentication(code: string) {
    return await this.formapi.getFormWithoutAuthentication(code);
  }

  async createNewQuestion(body) {
    return (await this.formapi.createNewQuestion(body)).data;
  }

  async deleteQuestion(body) {
    return await this.formapi.deleteQuestion(body);
  }

  async updateQuestion(body, questionId) {
    return await this.formapi.updateQuestion(body, questionId);
  }

  async insertForm(formCode, type: FillFormEnum, body) {
    let response = await this.formapi.insertForm(formCode, type, body);

    if (type == 'PUBLIC') return response;

    if (BaseResponse.isSuccess(response)) {
      response.data
        .map((item) => {
          return { ...item, clientData: { sheetId: item.sheetId } };
        })
        .forEach((item) => {
          this.itemEvents.emitItemCreated(item);
        });
    }

    return response;
  }

  async updateSection(sectionId, body) {
    return await this.formapi.updateSection(sectionId, body);
  }

  async createNewPage(body) {
    return await this.formapi.createNewPage(body);
  }

  async updatePage(pageId, body) {
    return await this.formapi.updatePage(pageId, body);
  }

  async updateForm(formId, body) {
    return await this.formapi.updateForm(body, formId);
  }
}
