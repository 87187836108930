<div class="row justify-content-center">
  <div [class.col-md-7]="formType ==FillFormEnum.PUBLIC">
    <div [class.form-container]="formType !=FillFormEnum.MODAL" class="position-relative">
      @if (!loaded) {
        <div
          style="width: 100%;display: flex;height: 300px;align-items: center;justify-content: center;align-content: center;flex-direction: row;flex-wrap: wrap;">
          <img width="56" height="56"
               src="assets/js/loading.gif">
        </div>
      } @else {
        <div class="intro-section" *ngIf="form.introPage"> {{ form.introPage.message }}</div>
        <br>
        <div class="row justify-content-center" style="padding:16px">
          <div *ngFor="let section of form.sections" class="col-12">
            <div *ngFor="let question of section.questions" class="mb-4">
              <form-item
                [id]="question.id.toString() || ''" [(value)]="question.value"
                [label]="question.label"
                [subline]="question.subLine"
                [isRtl]="isRtl"
                [orgId]="orgId"
                [question]="question"
                [required]="question.isRequired"/>
            </div>
          </div>

          @if (formType !== FillFormEnum.PREVIEW) {
            <div class="col-12">
              <button type="button" class="btn btn-primary w-100"
                      [class.disabled]="submitLoading"
                      (click)="submitForm()">
                      <span class="indicator-label" [class.d-none]="submitLoading">
                        {{ isRtl ? 'ثبت' : 'Confirm' }}
                      </span>
                <span class="indicator-progress"
                      [class.d-block]="submitLoading">{{ 'loading' | translate }}
                  <span class="spinner-border spinner-border-sm align-middle"></span>
                  </span>
              </button>
            </div>
          }
        </div>
      }
    </div>

    @if (formType == FillFormEnum.PUBLIC) {
      <div style="align-items: center;margin-top:10px;display:flex;justify-content: center">
        <a href="https://atwork.io" target="_blank" style="
    display: flex;
    color: #12394c;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
">
          <img
            src="https://r2.atwork.io/logo-256.png" width="24">
          <span>{{ 'POWERED_BY_ATWORK' | translate }}</span>
        </a>
      </div>
    }

  </div>
</div>
