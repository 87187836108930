import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBaseComponent } from '../form-base/form-base.component';

@Component({
  selector: 'form-long-text',
  templateUrl: './form-long-text.component.html',
  styleUrls: ['./form-long-text.component.scss']
})
export class FormLongTextComponent extends FormBaseComponent {

}
