import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ItemEvents} from "../../events/item.events";
import {FillFormEnum} from "../../data/enums/fill-form.enum";

@Component({
  selector: 'atw-form-fill-dialog',
  templateUrl: './form-fill-dialog.component.html',
  styleUrl: './form-fill-dialog.component.scss'
})
export class FormFillDialogComponent {
  formCode: string;

  constructor(private modal:NgbActiveModal,
              private itemEvents:ItemEvents
              ) {
  }

  init(){

  }

  onFormFilled() {
    this.modal.close();
  }

  protected readonly FillFormEnum = FillFormEnum;
}
