<div *ngIf="question.dataTypeName != DataTypeEnum.MultiSelect">
  <div ngbDropdown container="body" class="d-inline-block w-100 cursor-pointer">
    <div class="selected-chips" ngbDropdownToggle>
      {{ item?.Name ? item?.Name : ('select' | translate) }}
    </div>
    <div ngbDropdownMenu>
      <div class="title-holder">
        <input placeholder="{{'search' | translate}}" [(ngModel)]="searchText" class="title" style="cursor: text;"
               type="text">
      </div>
      <span ngbDropdownItem class="dropdown-menu-item" *ngFor="let option of options!! | chips_filter : searchText"
            (click)="selectItem(option)">{{ option.Name }}</span>
    </div>
  </div>
</div>

<div *ngIf="question.dataTypeName == DataTypeEnum.MultiSelect">
  <div class="form-check mb-3" *ngFor="let option of options">
    <input class="form-check-input" type="checkbox" value="{{option.id}}" id="{{option.id}}"
           (change)="toggleCheckbox($event)"/>
    <label class="form-check-label" for="{{option.id}}">
      {{ option.title }}
    </label>
  </div>
</div>
