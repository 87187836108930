import {Component} from '@angular/core';
import {FormBaseComponent} from "../form-base/form-base.component";

@Component({
  selector: 'form-number',
  templateUrl: './form-number.component.html',
  styleUrls: ['./form-number.component.sass']
})
export class FormNumberComponent extends FormBaseComponent {

}
