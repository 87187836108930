<form-label [required]="question.isRequired" [id]="id"
            [subline]="question.subLine"
            [label]="question.label"
            class="form-item-label"
            [style.direction]="isRtl?'rtl':'ltr'"
></form-label>

@if (question.dataTypeName == DataTypeEnum.CheckBox) {
  <form-checkbox [(value)]="value" [id]="id" [label]="label"
                 (valueChange)="onValueChange($event)"
                 [question]="question" [required]="required" [subline]="subline"></form-checkbox>
} @else if (question.dataTypeName == DataTypeEnum.Date) {
  <form-date [(value)]="value" [id]="id" [label]="label"
             (valueChange)="onValueChange($event)"
             [question]="question" [required]="required" [subline]="subline"></form-date>
} @else if (question.dataTypeName == DataTypeEnum.Email) {
  <form-email [(value)]="value" [id]="id" [label]="label"
              (valueChange)="onValueChange($event)"
              [question]="question" [required]="required" [subline]="subline"></form-email>
} @else if (question.dataTypeName == DataTypeEnum.File) {

  <form-file [(value)]="value" [id]="id" [label]="label"
             (valueChange)="onValueChange($event)" [orgId]="orgId"
             [question]="question" [required]="required" [subline]="subline"></form-file>

} @else if (question.dataTypeName == DataTypeEnum.LongText || question.dataTypeName == DataTypeEnum.Description) {
  <form-long-text [(value)]="value" [id]="id" [label]="label"
                  (valueChange)="onValueChange($event)"
                  [question]="question" [required]="required"
                  [subline]="subline"></form-long-text>
} @else if (question.dataTypeName == DataTypeEnum.Number) {
  <form-number [(value)]="value" [id]="id" [label]="label"
               (valueChange)="onValueChange($event)"
               [question]="question" [required]="required" [subline]="subline"></form-number>
} @else if (question.dataTypeName == DataTypeEnum.PhoneNumber) {
  <form-phone [(value)]="value" [id]="id" [label]="label"
              (valueChange)="onValueChange($event)"
              [question]="question" [required]="required" [subline]="subline"></form-phone>
} @else if ([DataTypeEnum.Select, DataTypeEnum.MultiSelect, DataTypeEnum.Team, DataTypeEnum.Job, DataTypeEnum.Location, DataTypeEnum.Employee, DataTypeEnum.Status].includes(question.dataTypeName)) {
  <form-select [(value)]="value" [id]="id" [label]="label"
               (valueChange)="onValueChange($event)"
               [question]="question" [required]="required" [subline]="subline"></form-select>
} @else if ([DataTypeEnum.ShortText, DataTypeEnum.Title].includes(question.dataTypeName)) {
  <form-short-text [(value)]="value" [id]="id" [label]="label"
                   (valueChange)="onValueChange($event)"
                   [isRtl]="isRtl"
                   [question]="question" [required]="required"
                   [subline]="subline"></form-short-text>
} @else if (question.dataTypeName == DataTypeEnum.URL) {
  <form-url [(value)]="value" [id]="id" [label]="label"
            (valueChange)="onValueChange($event)"
            [question]="question" [required]="required" [subline]="subline"></form-url>
}
