import {Component, Input, OnInit} from '@angular/core';
import {FormBaseComponent} from '../form-base/form-base.component';
import {DataTypeEnum} from "../../../data/enums/data-type.enum";
import {log} from "../../../share/utils/logger";

@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent extends FormBaseComponent implements OnInit {
  options: any[] | undefined = [];
  values: string[] = [];
  searchText: string;
  item: any = undefined;

  ngOnInit() {
    if (this.question.dataTypeName == DataTypeEnum.Status) {
      const options: any[] = [];
      Object.keys(this.question.options!!).forEach(key => options.push(...this.question.options!![key]));
      this.options = options.map(option => {
        option['Name'] = option.title;
        return option;
      });
    } else if (this.question.dataTypeName == DataTypeEnum.MultiSelect) {
      this.options = this.question.options?.items!!;
    } else if (this.question.dataTypeName == DataTypeEnum.Select) {
      this.options = this.question.options?.items?.map(item => {
        item['Name'] = item.title;
        return item;
      })!!;
    } else if (this.question.dataTypeName == DataTypeEnum.Employee) {
      this.options = this.question.options?.Employee?.map(option => {
        option['Name'] = `${option.FullName.firstName} ${option.FullName.lastName}`;
        return option;
      })!!;
    } else if (this.question.dataTypeName == DataTypeEnum.Team) {
      this.options = this.question.options?.Team;
    } else if (this.question.dataTypeName == DataTypeEnum.Job) {
      this.options = this.question.options?.Job;
      log('job', this.options);
    } else if (this.question.dataTypeName == DataTypeEnum.Location) {
      this.options = this.question.options?.[this.question.dataTypeName].map(option => {
        option['Name'] = option.name;
        return option;
      });
    } else {
      // this.options = this.question.options;
    }
  }

  selectItem(item) {
    this.valueChange.emit(item.id);
    this.item = item;
  }

  toggleCheckbox(event) {
    if (event.target.checked) {
      this.values.push(event.target.value);
    } else {
      const index: number = this.values.findIndex(value => value == event.target.value);
      this.values.splice(index, 1);
    }
    this.valueChange.emit(this.values);
  }

  protected readonly DataTypeEnum = DataTypeEnum;
}
